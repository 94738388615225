.input {
	box-shadow: none;
	border: none;
	border-bottom: 1px solid #dbdbdb;
	border-radius: 0;
	font-weight: 300;
	font-family: 'Fira Sans', sans-serif;
	width: 95%;
	@media screen and (max-width: 485px) {
		text-align: center;
		width: 100%;
	}

}

input.input__footer {
	background-color: #04294f;
	color: white;
}

.input__footer::placeholder {
	color: #dbdbdb;
}

@media screen and (max-width: 769px) {
	.field.is-grouped > .control:not(:last-child) {
		margin-right: 0;
	}
}
