.Features {
	max-width: 100vw;
	margin: 80px 0 0 0;
}

.Features__columns {
	height: 100vh;
	// Reverse every other row
	&:nth-of-type(even) {
		flex-direction: row-reverse;
		> .column .Features_textbox {
			@media screen and (min-width: 769px) {
				padding-left: 6.25rem;
			}
		}
	}
	&:nth-of-type(odd) {
		> .column {
			@media screen and (min-width: 769px) {
				// padding-right: 6.25rem;
				> .Features__image {
					margin: 0 0 0 auto;
				}
			}
		}
		> .column .Features_textbox {
			@media screen and (min-width: 769px) {
				padding-right: 6.25rem;
			}
		}
	}

	&:not(:last-of-type) {
		padding-bottom: 1.5rem;
		@media screen and (min-width: 769px) {
			padding-bottom: 2.5rem;
		}
	}
	@media screen and (max-width: 769px) {
		height: auto;
	}
	> .column {
		// height: 35vh;
	}
}

.Features__title {
	@media screen and (min-width: 769px) {
		margin-top: 20vw;
	}
}

.Features_textbox {
	@media screen and (min-width: 769px) {
		transform: translateY(-50%);
	}
	h3.title {
		font-size: 2.5rem;
		font-weight: 300;
		@media screen and (max-width: 769px) {
			margin-bottom: 1.25rem;
		}
	}
	p.subtitle {
		font-size: 1.625rem;
		opacity: 0.54;
		font-weight: 200;
		@media screen and (max-width: 769px) {
			margin-bottom: 3.125rem;
		}
	}
}

.Features__image {
	width: 40vw;
	// margin: 0 auto 0 0;
	// margin: 0 0 0 auto;
	@media screen and (max-width: 769px) {
		width: calc(100vw - 3rem);
		margin-bottom: 6.25rem;
	}

	// @media screen and (max-width: 769px) {
	//   margin: 0 auto;
	// }
}

.bg-block {
	&.odd {
		transform: translate(20vw, -4vw);
	}
	&.even {
		transform: translate(-20vw, -4vw);
	}
	@media screen and (max-width: 769px) {
		width: calc(100vw - 3rem);
		height: calc(100vw - 3rem);
		&.odd {
			transform: translate(1.5rem, 1.5rem);
		}
		&.even {
			transform: translate(-1.5rem, 1.5rem);
		}
	}
	width: 40vw;
	height: 48vw;
	background-color: #04294f;
	position: absolute;
}
