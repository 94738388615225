.SectionHeader__no-classname {
  margin-bottom: 14vw;
  // Remove margin if nothing after header
  &:last-child {
    margin-bottom: 0;
  }

  // Added if props.centered is true
  &.is-centered {
    text-align: center;
  }

  .subtitle {
    max-width: 700px;
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: inline-block;
    opacity: 0.54;
    font-weight: 300;
  }

  .title{
    font-weight: 900;
  }

}
