// NEW STUFF
.button {
	border-radius: 2px;
	font-size: 0.8rem;
}

.button:hover, .button:focus{
	color: white;
	font-weight: 400;
	box-shadow: 1px 2px 4px rgba(0, 0, 0, .3);
}

.is-cta {
	background-color: #ff564f;
	color: white;
	font-weight: 300;
	border: none;
}
