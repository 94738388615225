@charset "utf-8";

// COLORS
$primary: #04294F;
$info: #209CEE;
$cta: #FF564F;
$success: #23D160;
$warning: #FFDD57;
$danger: #FF3860;
$light: #F5F5F5;
$dark: #363636;
$input-focus-border-color: rgba(255,255,255, 0);


// TEXT
$text: #4A4A4A;
$link: #3273DC;
$body-family: "Open Sans", BlinkMacSystemFont, -apple-system, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$title-family: "Fira Sans", sans-serif;
$button-family: "Fira Sans", sans-serif;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3vw 1.5rem;
$section-padding-medium: 6vw 1.5rem;
$section-padding-large: 9vw 1.5rem;


// SEE DOCS wFOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA
@import "~bulma/bulma.sass";
