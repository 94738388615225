.input {
	box-shadow: none;
	border: none;
	border-bottom: 1px solid #dbdbdb;
	border-radius: 0;
	font-weight: 300;
	font-family: 'Fira Sans', sans-serif;
}

.subtitle{
	font-weight: 300;
}

input:focus {
	font-weight: 300;
	border-bottom: 1px solid #797979;
	outline: none;
	outline-width: 0;
}

button {
	@media screen and (max-width: 769px) {
		// margin-bottom: 14vw;
		margin-bottom: 2rem;
	}
}

.CTA__container__footer {
	@media screen and (max-width: 769px) {
		margin: 3rem 0;
	}
}
