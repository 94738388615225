.HeroSection__image {
  margin: 0 auto;
  max-width: 430px;
}

.HeroSection__image-container{
  @media screen and (min-width: 769px) {
    // padding-right: 6.25rem;
  }
}

.SectionComponent .hero{
  background-color: black;
}
